<template>
  <div style="width: 342px">
    <v-form
      @submit.prevent="changeName"
      class="px-2">
      <v-row
        class="mb-4"
        no-gutters>
        <v-text-field
          color="primaryColor"
          v-model="name">
          <template slot="label"> Enter your <strong>first name</strong> </template>
        </v-text-field>
      </v-row>

      <v-row
        justify="center"
        no-gutters>
        <v-col cols="10">
          <v-btn
            large
            type="submit"
            :loading="isChangingName"
            color="brand-gradient white--text"
            :disabled="isNameDisabled"
            rounded
            elevation="0"
            block
            depressed
            class="btn-submit"
            >UPDATE NAME</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import ChangeNameMixin from '@/views/profile/settings/change-name/ChangeName.mixin.vue';
  export default {
    mixins: [ChangeNameMixin],
  };
</script>

<style scoped>
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
  .v-label.theme--light {
    color: var(--v-brandGrey2-base) !important;
    top: -10px !important;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    color: white !important;
  }
  .theme--light.v-label.v-label--active {
    margin-bottom: 40px;
  }
  .v-label {
    top: -10px !important;
  }
  .btn-submit {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #ffffff;
  }
</style>
